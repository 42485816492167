<div class="row d-flex mx-0 align-items-center justify-content-center">
    <div class="col-12">
        <h5>{{data.note_title}}</h5>
        <p>{{data.note_desc}}</p>
    </div>
    <div class="col-12">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col">
                <button mat-button color="primary" type="button" (click)="openNotification()"><span class="mdi mdi-eye-circle-outline"></span> Open</button>
            </div>
            <div class="col">
                <button mat-button color="accent" type="button" (click)="closeNotification()"><span class="mdi mdi-close-circle-outline"></span> Close</button>
            </div>
        </div>
    </div>
</div>