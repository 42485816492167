import { Component, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef ,} from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { CommonService } from '../../shared/common.service';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
@Component({
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
  standalone: true,
  imports: [
    CommonModule,MatIconModule,SharedModule,
  ]
})
export class DeletePopupComponent {
  public dialogData: any = [];
  constructor(public cs: CommonService, private dialogRef: MatDialogRef<DeletePopupComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.dialogData=data;
this.cs.userSession=this.dialogData.userSession;
this.cs.projectTableName=this.dialogData.Table;
  }
  public closeform(type = 0) {
    this.dialogRef.close({ type: type });
    // if(this.cs.deleteDialogRef){
    //   this.cs.deleteDialogRef.close({ type: type });
    // }
  }
  async deleteRecord() {
    if (this.dialogData.FormType == 1) {
      let postDta =
        { scheema: this.dialogData.Scheema, procname: "setrecstatus", vals: [this.dialogData.Id, 0, this.dialogData.Table, this.dialogData.TableId, '', this.cs.userSession.user_id] };
      let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));

      if (result.status == 1) {
        this.cs.openSnackBar("Deleted Successfully");
        this.closeform(1)
      }
    } else if (this.dialogData.FormType == 2) {
      let postDta =
        { scheema: this.dialogData.Scheema, procname: "delete_equip_trans_request", vals: [this.dialogData.Id,this.dialogData.categoryId,this.cs.userSession.user_id] };
      let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));

      if (result.status == 1) {
        this.cs.openSnackBar("Deleted Successfully");
        this.closeform(1)
      }
    } else  if (this.dialogData.FormType == 3) {
      let postDta =
        { scheema: this.dialogData.Scheema, procname: "setrecstatus", vals: [this.dialogData.Id, 1, this.dialogData.Table, this.dialogData.TableId, '', this.cs.userSession.user_id] };
      let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));

      if (result.status == 1) {
        this.cs.openSnackBar("Deleted Successfully");
        this.closeform(1)
      }
    } else if (this.dialogData.FormType == 4) {
      let postDta =
        { scheema: this.dialogData.Scheema, procname: "setrecstatus", vals: [this.dialogData.Id, 1, this.dialogData.Table, this.dialogData.TableId, '', this.cs.userSession.user_id] };
      let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));

      if (result.status == 1) {
        this.cs.openSnackBar("Deleted Successfully");
        this.closeform(1)
      }
    } else if (this.dialogData.FormType == 5) {
      let postDta =
        { scheema: this.dialogData.Scheema, procname: "delete_travel_request", vals: [this.dialogData.Id,this.cs.userSession.user_id] };
      let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
      if (result.status == 1) {
        this.cs.openSnackBar("Deleted Successfully");
        this.closeform(1)
      }
    }
     else {
      let postDta =
        { scheema: this.dialogData.Scheema, procname: this.dialogData.Funcation, vals: [this.dialogData.Id, this.cs.userSession.user_id] };
      let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
      if (result.status == 1) {
        this.cs.openSnackBar("Deleted Successfully");
        this.closeform(1)
      }
    }
  }
}
