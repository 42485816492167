import { Injectable, Inject, ApplicationRef, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http'
import { map, tap } from 'rxjs/operators';
import { Subject, lastValueFrom } from 'rxjs';
import $ from "jquery";
import { saveAs } from 'file-saver';
import { firstValueFrom } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from './../environments/environment';
import { DeletePopupComponent } from '../app/delete-popup/delete-popup.component';
import { provideUserIdleConfig, UserIdleService } from 'angular-user-idle';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": 'application/json'
  })
}
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
}
  from "@angular/material/snack-bar";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { FormArray } from '@angular/forms';
import moment from 'moment';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ToWords } from 'to-words';
import { SwPush } from '@angular/service-worker';
import { NotficationsnackComponent } from '../app/notficationsnack/notficationsnack.component';
import { StatusTableComponent } from '../app/status-table/status-table.component';
import { Md5 } from 'ts-md5';
const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: { // can be used to override defaults for the selected locale
      name: 'Rupee',
      plural: 'Rupees',
      symbol: '₹',
      fractionalUnit: {
        name: 'Paisa',
        plural: 'Paise',
        symbol: '',
      },
    }
  }
});
@Injectable({
  providedIn: 'root'
})

export class CommonService {
  public property_types: any = [{ id: 1, name: 'Net Holdings as on FY ending' }, { id: 2, name: 'Acquired during the Year' }, { id: 3, name: 'Disposed during the year' }]
  public nature_propertys: any = [{ id: 1, name: 'House' }, { id: 2, name: 'Flat' }, { id: 3, name: 'Plot' }, { id: 4, name: 'Agricultural Land' }, { id: 5, name: 'Non Agricultural Land' }, { id: 6, name: 'Others' }]
  public updatePasswordPattern = '^(?!.*(?:0123|1234|2345|3456|4567|5678|6789|7890))(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,20}$';
  public patterns = {
    fullname: '[a-zA-Z ]*', email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,8}$/,
    mobile: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/, pan: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
    pfregex: /^[a-zA-Z0-9-/]*$/, uan: /^\d{12}$/,
  };
  public showPassword: any = false;
  public attendence_type: number = 1
  public dialogRef: any;
  tableActions = new Subject<any>();
  deleteActions = new Subject<any>();
  showDetails = new Subject<any>();
  adminmenuChangeAction = new Subject<any>();
  disableActions = false;
  public maintenace_type = 0;
  public intervalId: any = "";
  public adminmenuItems = [];
  public adminModules: any = [];
  public subModules: any = [];
  public selectedAdminModule: any = {};
  public recordsCount = 0;
  public curentDate = new Date();
  public toWords = toWords;
  public mediaUrl = environment.hosturl + 'media/';
  public hostUrl = environment.hosturl;
  public sub: PushSubscription | undefined;
  public deleteDialogRef!: MatDialogRef<DeletePopupComponent>;
  public access_rights: any = {
    view_access: 1,
    view_detail_access: 1,
    add_access: 1,
    edit_access: 1,
    delete_access: 1,
    import_access: 1,
    export_access: 1,
    subordinate_record_access_level: 1,
  };
  public relationTable: any = [
    { id: 1, name: "Father" },
    { id: 2, name: "Mother" },
    { id: 3, name: "Father-In-Law" },
    { id: 4, name: "Mother-In-Law" },
    { id: 5, name: "spouse_name" },
    { id: 6, name: "Brother" },
    { id: 7, name: "Sister" },
    { id: 8, name: "Son" },
    { id: 9, name: "Daughter" },
    { id: 10, name: "Others" },
  ]
  public tkt_priority = [
    { id: 0, name: "NA" },
    { id: 1, name: "Low" },
    { id: 2, name: "Medium" },
    { id: 3, name: "High" },
  ];
  public tkt_status = [
    { id: 0, name: "All" },
    { id: 1, name: "Raised" },
    { id: 2, name: "Assigned" },
    { id: 3, name: "Processing" },
    { id: 4, name: "On Hold" },
    { id: 5, name: "Resolved" },
    { id: 6, name: "Closed" },
  ];
  public tkt_status_raiser = [{ id: 6, name: "Closed" }];
  public tkt_status_solver = [{ id: 3, name: "Processing" }, { id: 5, name: "Resolved" }];
  public tkt_status_assigner = [

    { id: 2, name: "Assigned" },
    { id: 3, name: "Processing" },
    { id: 4, name: "On Hold" },
    { id: 5, name: "Resolved" },

  ];
  public years = Array.from(Array(new Date().getFullYear() - 2022), (_, i) => (i + 2023))
  public regularizationRowClass = { null: '', true: 'request-processed-row', false: 'request-rejected-row' };
  public regularizationStatusClass = { null: 'request-pending', true: 'request-approved', false: 'request-rejected' };
  public regularizationStatus = { null: 'Pending', true: 'Approved', false: 'Rejected' };
  public requestType = ['', 'Day', 'Week', 'Month', 'Custom'];
  public url = "http://localhost:3000";
  public uriData: any = { id: '', type: 'a' }
  public details_loaded = 0;
  public stocks_Categories = [{ id: 1, name: 'Transfers' }, { id: 2, name: 'Purchase Returns' }, { id: 3, name: 'Invoice' }, { id: 4, name: 'Sale Returns' }, { id: 5, name: 'Bills' }];
  public payment_for: any = [{ id: 1, name: 'Invoice', fn: 'get_invoices_list' }, { id: 2, name: 'Bill', fn: 'get_invoices_list' }, { id: 3, name: 'Salaries', fn: 'get_employees_list' }, { id: 4, name: 'Travel', fn: 'get_travels_list' }, { id: 5, name: 'Loan Disbursement', fn: 'get_loan_search' }];
  public leadType: any = [{ id: 1, name: 'Online' }, { id: 2, name: 'Offline' }, { id: 3, name: 'User' }];
  public leadSource: any = [{ id: 1, name: 'Website' }, { id: 2, name: 'Contact' }, { id: 3, name: 'Other Source' }];
  public saluation_list: any = [{ id: 1, name: 'Mr.' }, { id: 2, name: 'Miss.' }, { id: 3, name: 'Mrs.' }, { id: 4, name: 'M/S.' }];
  public customer_type: any = [{ id: 1, name: 'Company' }, { id: 2, name: 'Individual' }];
  public component_types: any = [{ id: 1, name: 'Earning' }, { id: 2, name: 'Deduction' }]
  public horizontalPosition: MatSnackBarHorizontalPosition = "right";
  public verticalPosition: MatSnackBarVerticalPosition = "top";
  public orgDetails = { gstin: '36ADEFS7978L1Z5' };
  public invoice_type_list = [{ id: 1, name: 'Full Invoice' }, { id: 2, name: 'Partial Invoice' }]
  public delivery_method_list: any = [{ id: 1, name: 'By Post' }, { id: 2, name: 'By Email' }, { id: 3, name: 'E-Invoicing' }];
  public payment_methods: any = [{ id: 1, name: 'Bank Transfer' }, { id: 2, name: 'Cheque Payment' }, { id: 3, name: 'Cash' }];
  public account_types: any = [{ id: 1, name: 'Savings Account' }, { id: 2, name: 'Current Account' }];
  public Credit_reasons: any = [{ id: 1, reason: 'Sale Return' }, { id: 2, reason: 'Post Sale Discount' }, { id: 3, reason: 'Deficiency in Service' }, { id: 4, reason: 'Correction in Invoice' }, { id: 5, reason: 'Charge in POS(place of supply)' }, { id: 6, reason: 'Finalization of Provisional Assessment' }, { id: 7, reason: 'Others' }];
  public Debit_reasons: any = [{ id: 1, reason: 'Purchase Return' }, { id: 2, reason: 'Post Purchase Discount' }, { id: 3, reason: 'Deficiency in Service' }, { id: 4, reason: 'Correction in Bill' }, { id: 5, reason: 'Charge in POS(place of supply)' }, { id: 6, reason: 'Finalization of Provisional Assessment' }, { id: 7, reason: 'Others' }];
  public userSession: any = {};
  public product_risk_list: any = [{ id: 1, name: 'Not risky' }, { id: 2, name: 'Moderate risky' }, { id: 3, name: 'Risky' }, { id: 4, name: 'Very Risky' }];
  public YearMonths = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', "Oct", "Nov", 'Dec']
  public Months: any = [{ id: 1, name: 'January' }, { id: 2, name: 'February' }, { id: 3, name: 'March' }, { id: 4, name: 'April' }, { id: 5, name: 'May' }, { id: 6, name: 'June' },
  { id: 7, name: 'July' }, { id: 8, name: 'August' }, { id: 9, name: 'September' }, { id: 10, name: 'October' }, { id: 11, name: 'November' }, { id: 12, name: 'December' }];
  public financialYearsList: any = [];
  public currentFY = this.getCurrentFinancialYear();
  public statusCats: any = { 'leavestatus': 2, 'travelstatus': 1 };
  public budgetType: any = [{ id: 1, name: 'Total Budget Cost' }, { id: 2, name: 'Total Budget Hours' }];
  public billingMethod: any = [{ id: 1, name: 'Fixed cost of Project' }, { id: 2, name: 'Based on Project Hours' }];
  public weeksList: any = [{ id: 1, name: 'Monday' }, { id: 2, name: 'Tuesday' }, { id: 3, name: 'Wednesday' }, { id: 4, name: 'Thursday' }, { id: 5, name: 'Friday' }, { id: 6, name: 'Saturday' }, { id: 7, name: 'Sunday' }]
  public itemtenure: any = [{ id: 1, name: 'Monthly' }, { id: 2, name: 'Quarterly' }, { id: 3, name: 'Half Yearly' }, { id: 4, name: 'Yearly' }];
  public transaction_type: number | undefined;
  static userSession: any;
  public currentDate: any = new Date();
  public projectTableName: any = '';
  // public sesc:any
  public mcqoptions: any = [{ id: 1, option: 'option 1' }, { id: 2, option: 'option 2' }, { id: 3, option: 'option 3' }, { id: 4, option: 'option 4' }]
  constructor( public userIdle: UserIdleService, private swPush: SwPush, public appref: ApplicationRef, @Inject(PLATFORM_ID) private platformId: Object,
    public _sanitizer: DomSanitizer, public router: Router, public dialog: MatDialog, public http: HttpClient, public snackBar: MatSnackBar) {
    this.userIdle.setConfigValues({ timeout: 900, idle: 30 });
    this.getfinancialYearsList();
    this.currentFY = this.getCurrentFinancialYear();
    //this.triggerNotificationSnack({note_title:'Test',note_desc:'dsfvdsf',note_url:'/dash/e-corner/tickets'});

  }
  sesc(content: string) {
    return content.replace(/'/g, "\\'").replace(/,/g, "\\,")
  }
  toggleShowPassword() {
    this.showPassword = true;
  }
  toggleHidePassword() {
    this.showPassword = false;
  }
  getCurrentFinancialYear() {
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    return fiscalyear
  }
  openStatusTable(id: any, orgId: number, moduleNm: any) {
    this.dialogRef = this.dialog.open(StatusTableComponent, {
      disableClose: true,
      width: "55vw",
      height: '65vh',
      panelClass: "dialog-responsive",
      data: {
        Id: id,
        orgId: orgId,
        module: moduleNm
      }
    });
  }
  getfinancialYearsList() {
    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 5; i++) {
      var next = currentYear + 1;
      var year = currentYear + '-' + next.toString().slice(-2);
      this.financialYearsList.push({ id: year, name: year });
      currentYear--;

    }
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }
  public updateViewCount(id: number, cateogry: number) {
    this.postData({
      scheema: "support", procname: "unique_view_count_update",
      vals: [this.userSession.user_id, cateogry, id, this.userSession.org_id],
    })
      .subscribe((data: any) => {
        if (data["status"] == "1") {
        } else {
        }
      });
  }
  postData(
    data: any, files = 0) {
    this.disableActions = false;
    let dta = data.info;
    if (files == 0) {
      dta = JSON.stringify(data.info);
    }
    return this.http.post(environment.hosturl + data.sourceid, dta, httpOptions)
      .pipe(
        tap(
          {
            next: (event) => {
              if (event instanceof HttpResponse) {
                this.disableActions = true;
              }
            },
            error: (error: HttpErrorResponse) => {
              //  this.render.removeClass(document.body, 'cursor-loader');
              if (error.error.error?.status && error.error.error?.status === 401 && error.error.error.code == 'invalid_token') {
                localStorage.removeItem("smarte2e_smato");
                this.router.navigate(['/']);
              } else {
              }
              // throw error;
            }
          }
        )
      )
  }

  public async userPwdReset(accountId: any, loggedInUserId: any, newpassword: any, orgId: any) {
    var newpdw = newpassword;
    if (newpassword !== 'default-password') {
      let md51 = new Md5();
      newpdw = md51.appendStr(newpassword).end();
    }
    let postDta =
    {
      scheema: "public", procname: "userpwd_reset",
      vals: [newpdw, accountId, loggedInUserId, orgId]
    }
    return await lastValueFrom(this.postData({ sourceid: "data/calldbproc", info: postDta }));
  }

  public useridlehandler() {
    try {
      clearInterval(this.intervalId);
    } catch (error) { }
    var that = this;
    this.intervalId = setInterval(function () {
      that.refreshtoken("");
    }, 500000);
  }
  makeFileRequest(url: string, params: any, files: Array<File>) {
    return new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      formData.append("filetype", params[0]);
      for (var i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i].name);
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open("POST", environment.hosturl + url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      const token: any = this.userSession.akey;
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + token
      );
      xhr.send(formData);
    });
  }
  public async downloadFIle(path: string, filname: string) {
    let fdownloadResult = await lastValueFrom(this.http.post(
      environment.hosturl + "import/templatedownload", JSON.stringify({ filecategory: path, filename: filname }),
      {
        headers: new HttpHeaders({
          Authorization: "Bearer " + this.userSession.akey,
        }), responseType: "blob"
      }
    ));
    saveAs(fdownloadResult, filname);

  }
  public getfile(path: string, filname: string) {
    let akey = this.userSession.akey;
    return this.http
      .post(
        environment.hosturl + "data/fdownload",
        JSON.stringify({ filecategory: path, filename: filname }),
        {
          headers: new HttpHeaders({
            Authorization: "Bearer " + akey,
            "Content-Type": 'application/json'
          }), responseType: "blob"
        }
      ) //set response Type properly (it is not part of headers)
    // .pipe( );
  }
  public refreshtoken(dta = '') {
    this.postData({
      sourceid: "account/genses",
      info: { id: this.userSession.user_id },
    }).subscribe((data: any) => {
      this.userSession["akey"] = data["akey"];
      localStorage.setItem(
        "smarte2e_smato",
        JSON.stringify(this.userSession)
      );
    });
  }
  public printpdf(dta:any = '') {
    this.postData({
      sourceid: "account/genses",
      info: dta,
    }).subscribe((data: any) => {
      this.userSession["akey"] = data["akey"];
      localStorage.setItem(
        "smarte2e_smato",
        JSON.stringify(this.userSession)
      );
    });
  }
  public uploadfiles(files: Array<File>) {
    return this.http.post(environment.hosturl + 'data/fupload', files);
  }
  async logout(type = '') {
    if (isPlatformBrowser(this.platformId)) {
      if (type == 'l') {
        this.dialog.closeAll();
        localStorage.setItem("smarte2e_smato", JSON.stringify({}));
       
        this.appref.tick();
        clearInterval(this.intervalId);
        await lastValueFrom(this.postData({
          sourceid: "data/calldbproc", info: {
            scheema: "hr", procname: "update_emp_notification_key",
            vals: [this.userSession.user_id, this.userSession.org_id, '']
          }
        }));
        this.userSession = {};
        // this.dialogRef.closeAll();
        this.router.navigate(["/account"]);
        // this.postData({
        //     sourceid: "logout",
        //     info: { id: this.userSession['login_history_id'] },
        // }).subscribe((data) => {
        //     this.userSession = {};
        // this.dialog.closeAll();
        // localStorage.setItem("smarte2e_smato", JSON.stringify({}));
        // this.appref.tick();
        // clearInterval(this.intervalId);

        // this.router.navigate(["/"]);
        //  });
      } else {
        this.userSession = {};
        this.dialog.closeAll();
        localStorage.setItem("smarte2e_smato", JSON.stringify({}));
        this.appref.tick();
        clearInterval(this.intervalId);
        this.router.navigate(["/account"]);
      }
    } else {
    }
  }
  openSnackBar(message: string) {
    // this.snackBar.open(message);
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = 3000;
    this.snackBar.open(message, "Close", config);
  }
  openDeletePopup(id: any, table: any, table_id: any, funcation: any, form_type: any, scheema: any) {
    this.deleteDialogRef = this.dialog.open(DeletePopupComponent, {
      disableClose: true,
      width: "25vw",
      panelClass: "dialog-responsive",
      data: {
        Id: id,
        Table: table,
        TableId: table_id,
        Funcation: funcation,
        FormType: form_type,
        Scheema: scheema,
        userSession: this.userSession,
        categoryId: 0
      }
    });
    this.deleteDialogRef.afterClosed().subscribe((result: any) => {
      this.deleteActions.next(result);
    });
  }
  // addPushSubscriber(sub: any) {
  //   return this.postData('/notifcations/addSubscription', sub);
  // }
  send() {
    return this.postData('/notifcations/newsletter', 0);
  }
  toHoursAndMinutes(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }
  public async printPage() {
    var timedt = moment(new Date()).format('lll');
    $('#printblock').html($('#printtable').html());
    $('#clientlogoprint').attr("src", $('#clientlogo').attr('src')!);
    //$('#userlogoprint').html($('#userlogo').html());
    // $('#producttitleprint').html($('#table-title span').text());
    $('#printitme').html(timedt);
    $('.waterprint-time').html(timedt);
    // $("#footer-by").html($('#logged-user').text());
    //  $('.waterprint-by').html('Anadrone Systems Private Limited (Publisher)');
    $('.waterprint-by').html('SMATO Apps');
    //$('.waterprint-by').html($('#logged-user').text());
    window.print();
    // $('#table-title mat-icon').css("display",'block');
  }
  public subscribeToNotifications() {
    try {
      if (!this.swPush.isEnabled) {
        console.log("Notification is not enabled.");
        return;
      }
      this.swPush.requestSubscription({
        serverPublicKey: environment.notifcationkey
      })
        .then(async (sub: PushSubscription | undefined) => {
          this.userSession['notification_key'] = sub;
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(
              "smarte2e_smato",
              JSON.stringify(this.userSession)
            );
          }
          let postDta =
          {
            scheema: "hr", procname: "update_emp_notification_key",
            vals: [this.userSession.user_id, this.userSession.org_id, JSON.stringify(sub)]
          };
          let result: any = await lastValueFrom(this.postData({ sourceid: "data/calldbproc", info: postDta }));
          this.handleNotifications();

        })
        .catch((err: any) => console.error("Could not subscribe to notifications", err))
    } catch (error) {

    }

  }
  public async handleNotifications() {
    try {
      this.swPush.messages.subscribe((arg: any) => {
        console.log(
          'Action: ' + arg.action,
          'Notification title: ' + arg.notification.title,
          'Notification data: ' + arg.notification.data,
          'Notification data.url: ' + arg.notification.data.url,
          'Notification data.body: ' + arg.notification.body,
        );
      //  this.triggerNotificationSnack(arg);
      })

      this.swPush.notificationClicks.subscribe(arg => {
        console.log(
          'Action: ' + arg.action,
          'Notification title: ' + arg.notification.title,
          'Notification data: ' + arg.notification.data,
          'Notification data.url: ' + arg.notification.data.url,
          'Notification data.body: ' + arg.notification.body,
        );
        if(arg.action=='navigaate'){
          this.router.navigateByUrl(arg.notification.data.note_url)
        }else{
        }
        console.log("note action else=",);
        //this.triggerNotificationSnack(arg.notification.data);
      });
      // let apires: any = await lastValueFrom(this.postData({ sourceid: "notifications/newsletter", info: { org_id: this.userSession.org_id, user_id: this.userSession.user_id, notication_key: JSON.stringify(this.userSession['notification_key']) } }));
    } catch (error) {

    }

  }
  public handleSockComunication(data: any) {
      // console.log("received Data==",data);
    this.triggerNotificationSnack(data);
  }
  public triggerNotificationSnack(data: any) {
    //message='',header='',actionLink='
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = 15000;
    config.data = data;
    config.panelClass=['success-snackbar']
    this.snackBar.openFromComponent(NotficationsnackComponent, config);
  }
}
