<div class="m-3 p-0">
<div class="row mx-0 mb-3">
  <div class="col-12 p-0 d-flex align-items-center justify-content-between border-b-1 py-2 ">
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==1">Definition of Travel Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==2">Definition of Leave Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==3">Definition of Travel Expenses Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==4">Definition of Attendance Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==5">Definition of Loan Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==13">Definition of Indent Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==14">Definition of Return Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==16">Definition of Tickets Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==23">Definition of Onduty Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==24">Definition of Overtime Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==25">Definition of Asset Declaration Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==21">Definition of Memo Statuses </h4>
    <h4 class="m-0 text-primary d-flex align-items-center" *ngIf="dialogData.Id==22">Definition of Transfer Statuses </h4>
    <span class="cursor" (click)="closeform(0)">
      <mat-icon>close</mat-icon>
    </span>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <table class="table table-bordered m-0 w-100">
      <thead class="sticky-header">
        <tr class="sticky-row">
          <th class="col-3">Status</th>
          <th class="col-9">Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let status of status_list">
          <td class="col-3">
            <p class="d-flex align-items-center">
              <ng-container *ngIf="status.status_nm!=null">

                <span [style.background-color]="'#'+status.status_color_code" class="fw-bold ps-2 "
                  class="circle-dot mx-1"></span>
                <label>{{status.status_nm}}</label>
              </ng-container>
            </p></td>
          <td class="col-9">{{status.status_desc}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
